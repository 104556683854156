.#{$app-prefix}-icon {
  &-full {
    fill: $andes-green-500;
    position: relative;
    top: $andes-spacing-4;
    height: $andes-spacing-16;
    width: 56px;
    margin-top: -1px;
  }
}
