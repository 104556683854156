.#{$social-prefix-desktop} {
  display: flex;
  justify-content: center;

  .#{$social-prefix-desktop}_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .aff-social-header__container {
      width: 100%;
    }
  }
  .#{$social-prefix-desktop}_content {
    max-width: 1200px;
    padding-top: 48px;
    .#{$social-prefix-desktop}_title {
      padding-bottom: $andes-spacing-32;
    }
  }
}
