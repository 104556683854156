// Base
@import '../../../styles/common';

// Variables
$social-prefix-desktop: '#{$app-prefix}-social-desktop';

// Andes
@import '~@andes/button/index';
@import '~@andes/card/index';
@import '~@andes/common/lib/styles/visually-hidden';
@import '~@andes/snackbar/index';
@import '~@andes/typography/index';

// Empty State & Technical Error
@import '../../../components/empty-state/desktop';
@import '../../../components/technical-error/index';

// Recommendations
@import '~@recommendations-fe/list/src/styles/recommendations-fe-list.mobile';
@import '~@recommendations-fe/carousel-snapped/src/styles/andes-bundle.desktop';
@import '~@recommendations-fe/carousel-snapped/src/styles/recommendations-fe-carousel-snapped.desktop';

// Polycard
@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

// Eshop
@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import 'eshops-components-library/src/components/AffiliateFeeInfo/lib/desktop';

// Internal
@import '../ui-components/profile/profile.desktop';
@import '../ui-components/denounce/desktop';
@import '../ui-components/header/desktop';
@import '../ui-components/recommendations-product-social/desktop';
@import '../../../components/card-featured/mobile';
@import '../../../components/tabs/desktop';

.#{$social-prefix-desktop} {
  width: var(--poly-max-width);
  display: flex;
  justify-content: center;

  .poly-card--list .poly-card__portada,
  .poly-card--list-card .poly-card__portada {
    --poly-image-size: 260px;

    margin: $andes-spacing-4;
  }

  .#{$app-prefix}-styled-label {
    padding: $andes-spacing-24 0;
    color: var(--text-andes-text-color-primary, $andes-gray-900);
    font-family: $font-family-primary;
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
  }

  .poly-content__column:nth-of-type(2) {
    display: flex;
    padding-left: 50px;
    justify-content: center;
  }

  .poly-component__action-links {
    width: var(--poly-max-width);
    display: flex;
  }

  .poly-action-links__action {
    width: 156px;
    height: 46px;
    border-color: transparent;
    background-color: $andes-blue-500;
    border-radius: 5px;
    box-shadow: unset;
    transition: box-shadow 0.25s ease-out, background-color 0.2s ease-out;
    cursor: pointer;

    .poly-component__link--action-link {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $andes-white;
    }
  }

  .andes-tabs__swipeable-content-scroll {
    padding: $andes-spacing-32 0;
  }

  .andes-tabs__swipeable-content {
    margin: 0;
  }

  .ui-recommendations-list__items-wrapper--grid {
    grid-template-columns: repeat(4, 1fr);
    background-color: transparent;
    gap: $andes-spacing-16;

    .poly-card--grid,
    .poly-card--grid-card {
      max-width: 100%;
    }

    .poly-card__portada {
      padding: 5px;
    }
  }

  .ui-recommendations-list__header {
    display: none;
  }

  .no-border {
    .ui-recommendations-list__items-wrapper--default
      .ui-recommendations-card--horizontal {
      border-bottom: none;
    }
  }

  .#{$app-prefix}-card-featured {
    border-top: none;
    border-bottom: none;
  }

  .ui-recommendations-list__container--grid {
    box-shadow: none;
  }

  &__carousel {
    width: 100%;

    .new-carousel {
      width: 100%;
      margin: 0;
    }

    .ui-recommendations-carousel-snapped
      .andes-carousel-snapped--scroll-hidden {
      padding: 0;
    }
  }
  .ui-recommendations-list__container--single,
  .ui-recommendations-list__items-wrapper--single {
    border: none;
    box-shadow: none;
  }
}
