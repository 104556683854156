@import './index';

.#{$app-prefix}-empty-state {
  background-color: #f5f5f5;

  .#{$app-prefix}-icon svg {
    margin-bottom: $andes-spacing-8;
  }

  // Andes overrides
  .andes-empty-state__illustration {
    height: 130px;
    margin-bottom: 0;
  }

  .andes-empty-state__description {
    display: flex;
    margin-top: $andes-spacing-8;
    margin-bottom: $andes-spacing-8;
  }
}
