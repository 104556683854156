// Commons
@import '../../styles/common';

@import '~@andes/technical-error/index';
@import '~@andes/badge/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/thumbnail/base';
@import '~@andes/thumbnail/lib/styles/modifier/circle';
@import '~@andes/typography/index';

.#{$app-prefix}-technical-error {
  height: 100vh;
  width: 100vw;
}
