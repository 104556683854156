/* stylelint-disable selector-class-pattern */

// Colors

.color-GREEN {
  color: $andes-success-green;
}

.color-GRAY {
  color: $andes-gray-550;
}

.color-LIGHT_GRAY {
  color: $andes-gray-070;
}

.color-BLACK {
  color: $andes-gray-900;
}

.color-RED {
  color: $andes-error-red;
}

.color-ORANGE {
  color: $andes-warning-orange;
}

.color-BLUE {
  color: $andes-blue-500;
}

.color-WHITE {
  color: $andes-white;
}

// Font sizes
$font-size-10: 10px;

.font-size-XXXSMALL {
  font-size: $font-size-10;
}

.font-size-XXSMALL {
  font-size: $font-size-12;
}

.font-size-XSMALL {
  font-size: $font-size-14;
}

.font-size-SMALL {
  font-size: $font-size-16;
}

.font-size-MEDIUM {
  font-size: $font-size-18;
}

.font-size-LARGE {
  font-size: $font-size-20;
}

.font-size-XLARGE {
  font-size: $font-size-24;
}

.font-size-XXLARGE {
  font-size: $font-size-28;
}

// Font weights

.font-family-LIGHT {
  font-weight: $font-weight-light;
}

.font-family-REGULAR {
  font-weight: $font-weight-regular;
}

.font-family-SEMIBOLD {
  font-weight: $font-weight-semibold;
}

.font-family-BOLD {
  font-weight: $font-weight-bold;
}

.background-color-BLUE {
  background-color: #3483fa;
}

.background-color-GREEN {
  background-color: $andes-green-500;
}

.background-color-WHITE {
  background-color: #fff;
}

.background-color-TRANSPARENT {
  background: $andes-blue-150;
}

.text-align-center {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
