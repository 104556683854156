.#{$app-prefix}-tabs {
  overflow-x: hidden;

  .andes-tabs__swipeable-content {
    overflow-y: hidden;
  }

  .andes-tab__link {
    margin: 0;
  }

  .andes-tab:hover:not(.andes-tab--disabled, .andes-tab--selected) {
    color: inherit;
  }

  .#{$app-prefix}-empty-state-content {
    text-align: center;
    padding: $andes-spacing-48 $andes-spacing-12;
  }

  .#{$app-prefix}-empty-state .#{$app-prefix}-title {
    padding: $andes-spacing-16 0 $andes-spacing-8 0;
  }

  .#{$app-prefix}-tabs-content:not(.#{$app-prefix}-tabs-content-swipeable) {
    padding: $andes-spacing-8 $andes-spacing-16;
  }

  .#{$app-prefix}-tabs-content.#{$app-prefix}-tabs-content-swipeable[aria-hidden='true'] {
    height: 0;
  }

  .ui-recommendations-list__header,
  .ui-recommendations-list__header-titles {
    border: none;
  }

  .polycard-web-list {
    border-top-right-radius: $border-radius-6;
    border-top-left-radius: $border-radius-6;
    box-shadow: 0 6px $andes-spacing-16 0 $andes-gray-100;
  }
}

.#{$app-prefix}-tabs-carousels {
  margin-top: $andes-spacing-8;
}
.#{$app-prefix}-tabs-list {
  margin-top: $andes-spacing-8;
}
