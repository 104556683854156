@import '~@andes/tabs/base';
@import '~@andes/tabs/lib/styles/desktop';

@import './index';

.#{$app-prefix}-tabs {
  margin-top: $andes-spacing-32;

  .andes-tabs__wrapper {
    background-color: transparent;
  }

  .andes-tabs__swipeable-content {
    margin: 0 $andes-spacing-8;
  }

  .andes-tab__link {
    width: fit-content;
  }

  .andes-tab--fullWidth {
    flex-grow: 0;
  }

  .andes-tabs--fullWidth {
    justify-content: flex-start;
  }

  .andes-tabs__swipeable-content-scroll--translated {
    transform: translateX(calc(-100% - 1em));
  }

  .andes-tabs__swipeable-content-scroll .andes-tab-content:not(:last-child) {
    margin-right: 1.1em;
  }
}

.#{$app-prefix}-tabs-animation-left-to-first
  .andes-tabs__swipeable-content-scroll {
  animation: tabs-fade-in 0.6s;
}

.#{$app-prefix}-tabs-animation-left .andes-tabs__swipeable-content-scroll {
  animation: tabs-fade-in 0.6s;
}

.#{$app-prefix}-tabs-animation-right .andes-tabs__swipeable-content-scroll {
  animation: tabs-fade-in 0.6s;
}

@keyframes tabs-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes tabs-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
