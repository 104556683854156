@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/quiet';
@import '~@andes/button/lib/styles/modifier/loud';

.#{$app-prefix}-empty-state {
  display: flex;
  justify-content: center;
  max-width: 1180px;
  margin: auto;
  padding: $andes-spacing-48 $andes-spacing-20 72px;

  &-content {
    width: 328px;
    text-align: center;
    margin: 0 auto;

    .andes-button__text {
      flex: none;
    }
  }

  .#{$app-prefix}-title {
    margin-bottom: 8px;
  }

  .#{$app-prefix}-actions {
    margin-top: 32px;
  }

  .#{$app-prefix}-button-search {
    margin-bottom: 8px;
  }
}
