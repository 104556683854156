$andes-theme: 'mercadolibre';

@import '~@andes/common/variables';
@import '~@andes/common/index';
@import '~@andes/normalize/index';
@import '~@andes/money-amount/base';
@import '~@andes/typography/index';

@import './variables';
@import './fonts';
@import './icons';

.rl {
  background-color: $andes-bg-primary;
  -webkit-font-smoothing: antialiased;

  .clipped {
    position: absolute;
    z-index: -1;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: 0 -1px -1px 0;
    overflow: hidden;
    padding: 0;
    display: block;
  }

  // Overrides - Recos
  .ui-recommendations-list__items-wrapper--grid {
    border-bottom: none;
  }

  // Overrides - Polycard
  .poly-card.poly-card--mobile {
    --poly-general-border-bottom: 1px solid #ededed;
    --poly-general-image-divider: unset;
    --poly-image-size: 110px;

    .poly-component__image-overlay {
      display: none;
    }

    .poly-card__portada--grid-height {
      height: auto;
    }

    &.poly-card--grid {
      &:nth-of-type(2n + 1) {
        --poly-general-border-right: 1px solid #ededed;
      }

      .poly-card__portada {
        padding: 4px 4px 0;
      }
    }

    &.poly-card--single {
      --poly-padding-card: 16px;
      --poly-padding-content: 0;

      /* stylelint-disable selector-class-pattern */
      &.poly-card--CORE {
        .poly-card__portada {
          height: fit-content;
        }
      }
    }
  }

  .ui-recommendations-carousel-free--compact {
    .poly-card.poly-card--mobile {
      &.poly-card--grid {
        &:nth-of-type(2n + 1) {
          --poly-general-border-right: unset;
        }
      }
    }
  }

  .ui-recommendations-list__container--grid {
    .poly-card.poly-card--mobile {
      --poly-min-width: 142px;
    }
  }

  /* stylelint-disable no-descending-specificity */
  .ui-recommendations-list__items-wrapper--default {
    .poly-card--list.poly-card--mobile,
    .poly-card--list.poly-card--mobile .poly-card__portada {
      --poly-image-size: 144px;
    }
  }

  // Overrides - Andes
  // Se oculta scrollbar de los carruseles de Andes para iOS y Firefox
  .andes-carousel-free {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
  }

  .andes-carousel-free::-webkit-scrollbar {
    display: none;
  }
}
