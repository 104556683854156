.#{$social-prefix-desktop} {
  .#{$social-prefix-desktop}-header {
    width: 100%;

    .ui-ms-profile__actions-wrapper{
      align-items: center;
    }

    .ui-ms-profile__followers-button {
      .andes-button{
        height: $andes-spacing-32;
      }
    }

    .ui-ms-profile__options-menu {
      right: 0;
      top: 0;

      .andes-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .andes-popper {
        height: auto !important;
      }
    }
  }
}
